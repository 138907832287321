import { ReactNode } from 'react';
import { toast, ExternalToast } from 'sonner';

type ToastOptions = {
  message?: ReactNode;
} & ExternalToast;

export const showSuccess = ({ message, ...data }: ToastOptions) => {
  toast.success(message, data);
};

export const showInfo = ({ message, ...data }: ToastOptions) => {
  toast.info(message, data);
};

export const showWarning = ({ message, ...data }: ToastOptions) => {
  toast.warning(message, data);
};

export const showError = ({ message, ...data }: ToastOptions) => {
  toast.error(message, data);
};
